import React from "react"
import { Link } from "gatsby"
import { Container, Navbar, Nav } from "react-bootstrap"

const CustomNavbar = ({ siteTitle, pageInfo }) => {
    console.log(pageInfo)
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="sm" id="site-navbar" fixed="top">
                <Container>
                    <Link to="/" className="link-no-style">
                        <Navbar.Brand as="span">{siteTitle}</Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
                            <Link to="/" className="">
                                <Nav.Link as="span" eventKey="index">
                                    Home
                                </Nav.Link>
                            </Link>
                            <Link to="/privacy" className="">
                                <Nav.Link as="span" eventKey="privacy">
                                    Privacy
                                </Nav.Link>
                            </Link>
                            <Link to="/#" className="link-no-style">
                                <Nav.Link as="span" eventKey="contact">
                                    Contact
                                </Nav.Link>
                            </Link>
                            <a href="https://app.workbench.dental" className="link-no-style" title="Sign in">
                                <Nav.Link as="span" eventKey="page-2">
                                    Sign in
                                </Nav.Link>
                            </a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default CustomNavbar