import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "react-bootstrap"
import Header from "./header"
import { Link } from "gatsby"
import '../styles/styles.scss'

const Layout = ({ children, pageInfo, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={className}>
      <Header siteTitle={data.site.siteMetadata.title} pageInfo={pageInfo} />
      <Container>
        {/* <Row noGutters className="justify-content-center">
          <Col>
            
          </Col>
        </Row> */}
        <main>{children}</main>
        <footer className="border-top footer mt-auto pb-2 pt-2 small">
          <Container>
            <div className="small">
              © {new Date().getFullYear()} - Smilesolver Technologies Ltd - <Link to="/privacy" className="link-no-style">Privacy</Link>
            </div>
          </Container>
        </footer>
      </Container>

    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
